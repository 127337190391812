import {
    createSelectorWithDependencies as createSelector
} from 'reselect-tools';


import moment from 'moment';
import {mean, fraction, number, add, subtract, multiply, divide, min, max, round} from 'mathjs';
import {getMonthsList} from "./appSelector";
import {calculateIdealPace, predictLeads} from 'erj-common/generalUtils';
import {calculateCommission, calculateCommissionRecommendation} from 'erj-common/commissionUtils';
import {getChildCentersMenu} from "../utils/generalUtils";

const _ = require('lodash');

const math = {fraction, number, add, subtract, multiply, divide, min, max, round};

const getCurrentCommissionUser = (state, props) => state.auth.sessionData.commissions_controlling_center === state.auth.sessionData.attached_center_id ? state.auth.sessionData.user_id : (
    Object.values(state.users.org_users[state.auth.sessionData.commissions_controlling_center]).reduce((result, user) => {
        return user.in_crm_id === state.auth.userData.in_crm_id ? user : result;
    })
);

const getCurrentUserId = (state, props) => state.auth.userData.id;
const getSessionData = (state, props) => state.auth.sessionData;
const getAttachedCenterId = (state, props) => state.auth.sessionData.attached_center_id;
const getUsers = (state, props) => state.users.users;
const getInactiveUsersData = (state, props) => state.users.inactive_users_data;
const getMetricsStore = (state, props) => state.metrics;
const getMetrics = (state, props) => state.metrics.metrics;
const getLeadTypes = (state, props) => state.auth.sessionData.commissions_controlling_center === state.auth.sessionData.attached_center_id ? state.metrics.lead_types : state.metrics.org_lead_types[state.auth.sessionData.commissions_controlling_center];
const getCurrentDate = (state, props) => state.global.currentDate;
const getCommissionFinalizeDate = (state, props) => state.auth.sessionData.commission_finalize_date;
const getCenterShifts = (state, props) => state.auth.sessionData.additionalData.center_shifts;
const getFirstDayOfMonth = (state, props) => state.auth.sessionData.additionalData.first_day_of_month;
const getCenterMonths = (state, props) => state.auth.sessionData.additionalData.months;
const getSessionAdditionalData = (state, props) => state.auth.sessionData.additionalData;
const getCommissionPlansForSimulation = (state, props) => props.plans;
const getIsSimulatingCurrentMonth = (state, props) => props.isCurrentMonth;
const getDataForCommissionSim = (state, props) => !props.isCurrentMonth && !props.isPrevMonth && state.commissions.data_for_commission_sim;
const getCommissionPlans = (state, props) => state.commissions.commission_plans;
const getMonthCommissionPlans = (state, props) => state.commissions.month_commission_plans;
const getMonthCommissions = (state, props) => state.commissions.month_commissions;
const getFinalMonthCommissions = (state, props) => props.isCommissionFinalized && state.commissions.month_commissions;
const getPreviousCommissions = (state, props) => state.commissions.previous_commissions;
const getCommissionMonth = (state, props) => state.commissions.month;

const getUnits = createSelector(
    [getSessionAdditionalData, getAttachedCenterId],
    (sessionAdditionalData, attached_center_id) => {
        const childCentersMenu = Object.keys(_.get(sessionAdditionalData, "centersDetails", {})).length > 1 ? getChildCentersMenu({
            child_centers: sessionAdditionalData.child_centers,
            centersDetails: sessionAdditionalData.centersDetails,
            allowedCenterIds: sessionAdditionalData.allowedCenterIds || [],
            root_center_id: String(attached_center_id)
        }).filter(menuItem => menuItem.lvl > 0).map(menuItem => ({
            ...menuItem,
            lvl: menuItem.lvl - 1,
            path: ['data', 'units']
        })) : [];

        return {
            list: sessionAdditionalData.centersDetails,
            menu: childCentersMenu
        };
    }
);

export const makeGetCommissionWizard = () => {
    let getCommissionWizard = createSelector(
        [getMonthsList, getUnits],
        (monthsList, units) => {
            return {
                units,
                monthsList
            }
        }
    );

    getCommissionWizard.selectorName = 'getCommissionWizard';
    return getCommissionWizard;
};

const getMetricsForUserCommission = createSelector(
    [getMetricsStore, getSessionData],
    (metrics, sessionData) => {
        if (sessionData.commissions_controlling_center === sessionData.attached_center_id) {
            return metrics.metrics;
        } else {
            const lead_types = metrics.lead_types;
            const org_lead_types = metrics.org_lead_types[sessionData.commissions_controlling_center];
            return Object.entries(metrics.metrics).reduce((metrics, [lead_type_id, metric]) => {
                let org_lead_type = Object.values(org_lead_types).filter(org_lead_type => org_lead_type.in_crm_id === lead_types[lead_type_id].in_crm_id)[0];
                if (org_lead_type) {
                    metrics[org_lead_type.id] = metric;
                }
                return metrics;
            }, {});
        }
    }
);

export const getMainCommissionPlan = createSelector(
    [getCommissionPlans],
    (commission_plans) => Object.values(commission_plans).filter(commission_plan => !commission_plan.user_id && commission_plan.month === '0000-00-00')[0]
);

export const getCurrentCommissionPlans = createSelector(
    [getCommissionPlans],
    (commission_plans) => Object.values(commission_plans).reduce((result, commission_plan) => {
        if (!result[commission_plan.user_id] || (result[commission_plan.user_id] && commission_plan.month !== '0000-00-00')) {
            result[commission_plan.user_id] = commission_plan.plan;
        }

        return result;
    }, {})
);

export const getCurrentUserCommissionPlan = createSelector(
    [getCurrentCommissionPlans, getCurrentCommissionUser],
    (current_commission_plans, user_id) => current_commission_plans[user_id] || current_commission_plans["0"]
);

export const makeGetMonthCommissionData = () => {
    let getMonthCommissionData = createSelector(
        [getMonthsList, getUsers, getMonthCommissions, getCurrentDate, getCommissionFinalizeDate, getCommissionMonth, getMonthCommissionPlans],
        (monthsList, users, month_commissions, currentDate, commission_finalize_date, chosenMonth, month_commission_plans) => {
            let monthTotalCommissions = math.fraction(0);
            let userMonthCommissions = {...month_commissions};
            const monthData = Object.values(users).map(user => {
                user = {...user};
                if (userMonthCommissions[user.id]) {
                    user.commission = userMonthCommissions[user.id].amount;
                    user.is_final = userMonthCommissions[user.id].is_final;
                    delete userMonthCommissions[user.id];
                    monthTotalCommissions = monthTotalCommissions.add(user.commission);
                }
                return user;
            });
            for (let {
                amount,
                is_final,
                user_id,
                first_name,
                last_name,
                center_id,
                user_img,
                is_active
            } of Object.values(userMonthCommissions)) {
                monthData.push({
                    id: user_id,
                    commission: amount,
                    is_final,
                    first_name,
                    last_name,
                    center_id,
                    user_img,
                    isActive: is_active
                });
                monthTotalCommissions = monthTotalCommissions.add(amount);
            }

            // if commission_finalize_date===0 then commission is finalized at the end of the following month => therefore, in this case prevMonth will always be not-finalized
            const isPrevMonthCommissionFinalized = commission_finalize_date && (
                moment(monthsList[monthsList.length - 1].start).add(commission_finalize_date - 1, 'days').isSameOrBefore(currentDate)
            );
            const currentMonth = monthsList[monthsList.length - 1];
            const prevMonth = monthsList[monthsList.length - 2];
            const isCommissionFinalized = chosenMonth !== currentMonth.value && (chosenMonth !== prevMonth.value || isPrevMonthCommissionFinalized);

            return {
                monthsList,
                monthData,
                monthTotalCommissions: math.number(monthTotalCommissions),
                isCommissionFinalized,
                isPrevMonthCommissionFinalized,
                isCurrentMonth: chosenMonth === currentMonth.value,
                isPrevMonth: chosenMonth === prevMonth.value,
                plansInEffect: Object.values(month_commission_plans).reduce((user_plans, {
                    user_id,
                    month,
                    plan
                }) => {
                    if (plan && (user_id !== 0 || !user_plans["0"] || month !== '0000-00-00')) {
                        user_plans[user_id] = plan;
                    }

                    if (user_id === 0 && month === '0000-00-00') {
                        user_plans["$main"] = plan;
                    }

                    return user_plans;
                }, {})
            }
        }
    );

    getMonthCommissionData.selectorName = 'getMonthCommissionData';
    return getMonthCommissionData;
};

export const makeGetCommissionResults = () => {
    let getCommissionResults = createSelector(
        [getUsers, getInactiveUsersData, getCurrentDate, getMetrics, getCommissionPlansForSimulation, getDataForCommissionSim, getIsSimulatingCurrentMonth, getCenterShifts, getFirstDayOfMonth, getCenterMonths, getLeadTypes, getFinalMonthCommissions, getUnits],
        (users, inActiveUsersData, currentDate, metrics, plans, data_for_commission_sim, isCurrentMonth, center_shifts, first_day_of_month, months, lead_types, final_month_commissions, units) => {
            let data;
            let predicted_data;
            if (data_for_commission_sim) {
                data = _.cloneDeep(data_for_commission_sim);
            } else {
                const inActiveUsersIds = Object.values(users).filter(user => !user.isActive && !user.in_crm_id && inActiveUsersData[user.id]).map(user => user.id);
                if (inActiveUsersIds.length) {
                    users = {...users};
                    for (let id of inActiveUsersIds) {
                        users[id] = {...users[id], ...inActiveUsersData[id]}
                    }
                }

                let monthly_performance = {};
                let monthly_goals_percent = {};
                let predicted_performance = {};
                let predicted_goals_percent = {};

                for (let [type_id, {leaderBoards}] of Object.entries(metrics)) {
                    if (type_id === '0') {
                        continue;
                    }

                    monthly_performance[type_id] = {};
                    predicted_performance[type_id] = {};
                    for (let {user_id, leads} of leaderBoards[isCurrentMonth ? "this_month" : "last_month"]) {
                        if (!user_id || !users[user_id]) {
                            continue;
                        }
                        monthly_performance[type_id][user_id] = leads;
                        if (isCurrentMonth) {
                            predicted_performance[type_id][user_id] = !users[user_id].isActive ? leads : predictLeads({
                                moment,
                                currentTime: moment(currentDate),
                                timeframe: 'monthly',
                                leads: leads,
                                work_days_goal: (metrics[type_id].goals[user_id] && metrics[type_id].goals[user_id].work_days) || 0,
                                actual_work_days: users[user_id].daysWorked,
                                last_shift_start: users[user_id].last_shift_start,
                                center_shifts,
                                first_day_of_month,
                                months,
                                origin_type: lead_types[type_id] && lead_types[type_id].origin_type
                            });
                        }
                    }
                }

                for (let [type_id, {goals}] of Object.entries(metrics)) {
                    if (type_id !== '0') {
                        monthly_goals_percent[type_id] = {};
                        predicted_goals_percent[type_id] = {};
                        for (let [user_id, {[isCurrentMonth ? "this_month" : "last_month"]: monthGoal}] of Object.entries(goals)) {
                            if (!user_id || !users[user_id]) {
                                continue;
                            }
                            monthly_goals_percent[type_id][user_id] = monthGoal ? _.get(monthly_performance, [type_id, user_id], 0) / monthGoal : 1;
                            if (isCurrentMonth) {
                                predicted_goals_percent[type_id][user_id] = !users[user_id].isActive ? monthGoal : calculateIdealPace({
                                    moment,
                                    currentTime: moment(currentDate),
                                    timeframe: 'monthly',
                                    goal: monthGoal,
                                    work_days_goal: (metrics[type_id].goals[user_id] && metrics[type_id].goals[user_id].work_days) || 0,
                                    actual_work_days: users[user_id] ? users[user_id].daysWorked : [],
                                    last_shift_start: users[user_id] ? users[user_id].last_shift_start : new Date(0),
                                    center_shifts,
                                    first_day_of_month,
                                    months,
                                    origin_type: lead_types[type_id] && lead_types[type_id].origin_type
                                });
                            }
                        }
                    }
                }

                data = {
                    monthly_performance,
                    goal_percent: monthly_goals_percent
                };

                if (isCurrentMonth) {
                    predicted_data = {
                        monthly_performance: predicted_performance,
                        goal_percent: predicted_goals_percent
                    };
                }
            }

            data.employee_data = {
                employed_since_months: Object.values(users).reduce((result, user) => {
                    result[user.id] = moment(currentDate).diff(user.employed_since, 'months', true)
                    return result;
                }, {}),
                role: Object.values(users).reduce((result, user) => {
                    result[user.id] = user.role;
                    return result;
                }, {}),
                group: Object.values(users).reduce((result, user) => {
                    result[user.id] = user.group;
                    return result;
                }, {}),
                sub_group: Object.values(users).reduce((result, user) => {
                    result[user.id] = user.sub_group;
                    return result;
                }, {}),
                assigned_center_ids: Object.values(users).reduce((result, user) => {
                    result[user.id] = user.assigned_center_ids;
                    return result;
                }, {})
            };

            data.team_average_monthly_performance = Object.entries(data.monthly_performance).reduce((result, [type_id, users_performance]) => {
                const teamAvg = Object.values(users_performance).length ? mean(Object.values(users_performance)) : 0;
                result[type_id] = Object.keys(users_performance).reduce((typeResults, user_id) => {
                    typeResults[user_id] = teamAvg;
                    return typeResults;
                }, {})
                return result;
            }, {});

            if (isCurrentMonth) {
                predicted_data.team_average_monthly_performance = Object.entries(predicted_data.monthly_performance).reduce((result, [type_id, users]) => {
                    const teamAvg = Object.values(users).length ? mean(Object.values(users)) : 0;
                    result[type_id] = Object.keys(users).reduce((typeResults, user_id) => {
                        typeResults[user_id] = teamAvg;
                        return typeResults;
                    }, {})
                    return result;
                }, {});

                predicted_data.employee_data = data.employee_data;
            }

            let totalCommissions = math.fraction(0);
            let totalPredictedCommissions = math.fraction(0);
            let commissionsResultsCache = {};
            const commissions = Object.keys(users).reduce((result, user_id) => {
                let commission = 0;
                let predictedCommission = 0;

                if (final_month_commissions) {
                    commission = final_month_commissions[user_id] ? final_month_commissions[user_id].amount : 0;
                    commissionsResultsCache[user_id] = final_month_commissions[user_id] ? final_month_commissions[user_id].calculations_cache.midResults : {};
                    data = final_month_commissions[user_id] ? final_month_commissions[user_id].calculations_cache.user_data : {};
                } else {
                    const plan = plans[user_id] || plans["0"];
                    if (!plan) {
                        return result;
                    }
                    commissionsResultsCache[user_id] = {};
                    commission = calculateCommission({
                        plan,
                        user_id,
                        data,
                        math,
                        resultsCache: commissionsResultsCache[user_id]
                    });
                    totalCommissions = math.add(totalCommissions, math.fraction(commission));
                    if (isCurrentMonth) {
                        predictedCommission = calculateCommission({
                            plan: plans[user_id] || plans["0"],
                            user_id,
                            data: predicted_data,
                            math
                        });
                        totalPredictedCommissions = math.add(totalPredictedCommissions, math.fraction(predictedCommission));
                    }
                }

                return result.concat({
                    user_id,
                    commission,
                    predictedCommission,
                    data,
                    resultsCache: commissionsResultsCache[user_id]
                });
            }, []).sort((a, b) => b.commission - a.commission);

            return {
                currentDate,
                commissions,
                totalCommissions: math.number(totalCommissions),
                totalPredictedCommissions: math.number(totalPredictedCommissions),
                isCurrentMonth,
                users,
                plans,
                lead_types,
                units
            }
        }
    );

    getCommissionResults.selectorName = 'getCommissionResults';
    return getCommissionResults;
};

/* TODO: currently makeGetCommissionSummary uses local team metrics and so may produce wrong team avgs for parent center controlled plans
    => need to add an org_metrics cache (and only provide the controlling center) and use that in here
 */
export const makeGetCommissionSummary = () => {
    let getCommissionSummary = createSelector(
        [getUsers, getCurrentDate, getMetricsForUserCommission, getCurrentUserCommissionPlan, getMainCommissionPlan, getCenterShifts, getFirstDayOfMonth, getCenterMonths, getLeadTypes, getCurrentUserId, getMonthsList, getPreviousCommissions],
        (users, currentDate, metrics, plan, main_commission_plan, center_shifts, first_day_of_month, months, lead_types, current_user_id, monthList, previous_commissions) => {
            let data;
            let predicted_data;
            let monthly_performance = {};
            let monthly_goals_percent = {};
            let predicted_performance = {};
            let predicted_goals_percent = {};

            for (let [type_id, {leaderBoards}] of Object.entries(metrics)) {
                if (type_id === '0') {
                    continue;
                }

                monthly_performance[type_id] = {};
                predicted_performance[type_id] = {};
                for (let {user_id, leads} of leaderBoards.this_month) {
                    if (!user_id) {
                        continue;
                    }
                    monthly_performance[type_id][user_id] = leads;
                    predicted_performance[type_id][user_id] = lead_types[type_id] ? predictLeads({
                        moment,
                        currentTime: moment(currentDate),
                        timeframe: 'monthly',
                        leads: leads,
                        work_days_goal: (metrics[type_id].goals[user_id] && metrics[type_id].goals[user_id].work_days) || 0,
                        actual_work_days: users[user_id] ? users[user_id].daysWorked : [],
                        last_shift_start: users[user_id] ? users[user_id].last_shift_start : new Date(0),
                        center_shifts,
                        first_day_of_month,
                        months,
                        origin_type: lead_types[type_id].origin_type
                    }) : 0;
                }
            }

            for (let [type_id, {goals}] of Object.entries(metrics)) {
                if (type_id !== '0') {
                    monthly_goals_percent[type_id] = {};
                    predicted_goals_percent[type_id] = {};
                    for (let [user_id, {this_month}] of Object.entries(goals)) {
                        if (!user_id) {
                            continue;
                        }
                        monthly_goals_percent[type_id][user_id] = this_month ? _.get(monthly_performance, [type_id, user_id], 0) / this_month : 1;
                        predicted_goals_percent[type_id][user_id] = calculateIdealPace({
                            moment,
                            currentTime: moment(currentDate),
                            timeframe: 'monthly',
                            goal: this_month,
                            work_days_goal: (metrics[type_id].goals[user_id] && metrics[type_id].goals[user_id].work_days) || 0,
                            actual_work_days: users[user_id] ? users[user_id].daysWorked : [],
                            last_shift_start: users[user_id] ? users[user_id].last_shift_start : new Date(0),
                            center_shifts,
                            first_day_of_month,
                            months,
                            origin_type: lead_types[type_id].origin_type
                        });
                    }
                }
            }

            data = {
                monthly_performance,
                goal_percent: monthly_goals_percent
            };

            predicted_data = {
                monthly_performance: predicted_performance,
                goal_percent: predicted_goals_percent
            };

            data.employee_data = {
                employed_since_months: Object.values(users).reduce((result, user) => {
                    result[user.id] = moment(currentDate).diff(user.employed_since, 'months', true)
                    return result;
                }, {}),
                role: Object.values(users).reduce((result, user) => {
                    result[user.id] = user.role;
                    return result;
                }, {}),
                group: Object.values(users).reduce((result, user) => {
                    result[user.id] = user.group;
                    return result;
                }, {}),
                sub_group: Object.values(users).reduce((result, user) => {
                    result[user.id] = user.sub_group;
                    return result;
                }, {}),
                assigned_center_ids: Object.values(users).reduce((result, user) => {
                    result[user.id] = user.assigned_center_ids;
                    return result;
                }, {})
            };

            data.team_average_monthly_performance = Object.entries(data.monthly_performance).reduce((result, [type_id, users]) => {
                const teamAvg = mean(Object.values(users));
                result[type_id] = Object.keys(users).reduce((typeResults, user_id) => {
                    typeResults[user_id] = teamAvg;
                    return typeResults;
                }, {})
                return result;
            }, {});

            predicted_data.team_average_monthly_performance = Object.entries(predicted_data.monthly_performance).reduce((result, [type_id, users]) => {
                const teamAvg = mean(Object.values(users));
                result[type_id] = Object.keys(users).reduce((typeResults, user_id) => {
                    typeResults[user_id] = teamAvg;
                    return typeResults;
                }, {})
                return result;
            }, {});

            predicted_data.employee_data = data.employee_data;

            let commission = 0;
            let predictedCommission = 0;
            let resultsCache = {};
            let recommendations = [];
            //let displayColumns = main_commission_plan.display_columns.filter(column => column[2] !== 'assigned_center_ids'); // Cannot read properties of undefined (reading 'display_columns')
            // Thanks to osher
            let displayColumns = main_commission_plan?.display_columns?.filter(column => column[2] !== 'assigned_center_ids')||[]; // Cannot read properties of undefined (reading 'display_columns')
            if (plan) {
                commission = calculateCommission({plan: plan, user_id: current_user_id, data, math, resultsCache});
                predictedCommission = calculateCommission({
                    plan: plan,
                    user_id: current_user_id,
                    data: predicted_data,
                    math
                });
                ({recommendations} = calculateCommissionRecommendation({
                    plan,
                    user_id: current_user_id,
                    data: {
                        ...data,
                        lead_types
                    },
                    math
                }));
            }

            let modal_data = [];
            if (previous_commissions.length) {
                const months = _.keyBy(monthList, 'value');
                modal_data = previous_commissions.map(row => ({
                    month: months[row.month].title + ' (' + months[row.month].key + ')',
                    commission: row.amount,
                    is_final: row.is_final
                }));
            }

            return {
                data,
                current_user_id,
                savedComponents: Object.entries(main_commission_plan.plan ? main_commission_plan.plan.components : {}).reduce((result, [key, component]) => {
                    if (key !== 'main' && component.type !== 'tier_table') {
                        result[key] = component;
                    }
                    return result;
                }, {}),
                plan,
                commission,
                predictedCommission,
                resultsCache,
                displayColumns,
                modal_data,
                lead_types,
                recommendations
            };
        }
    );

    getCommissionSummary.selectorName = 'getCommissionSummary';
    return getCommissionSummary;
};