const _ = require("lodash");

const runGeneratorFunction = (func, ...args) => {
    let result, state;
    let caller = func(...args);
    let states = [];
    while (!state || !state.done) {
        state = caller.next(result);
        if (state.value !== undefined) {
            result = state.value;
            states.push(state.value);
        }
        // console.log({state: state.value,result});
    }
    if ((typeof (window) !== 'undefined' && window.debugGeneratorStates) || (typeof (process) !== 'undefined' && process.env && process.env.debugGeneratorStates)) {
        console.log({states, args});
    }
    return {states, result};
};

const changeByPercent = (value, multiplier) => {
    return value + (value * multiplier);
};

const calculateCommissionRecommendation = ({
                                               plan,
                                               currentNode,
                                               user_id,
                                               data,
                                               math,
                                               resultsCache,
                                               isUnlinked = false
                                           }) => {
    if (isUnlinked) {
        return;
    }
    console.log('calculating recommendation for ' + user_id);
    let {states, result: originalResult} = runGeneratorFunction(calculateCommissionPart, {
        plan,
        currentNode,
        user_id,
        data,
        math,
        resultsCache,
        isUnlinked
    });
    let statesTree = {};
    for (let i = states.length - 1; i >= 0; i--) { // final results are last so this will build itself without overwriting anything
        _.set(statesTree, states[i].currentPath, states[i]);
    }
    console.log({statesTree});
    let recommendations = [];
    for (let i = states.length - 1; i >= 0; i--) {
        const state = states[i];
        if (state.currentNode.type === "condition") { //this is a condition => try to reach a different result
            const result = optimizeCondition({plan, statesTree, currentPath: state.currentPath, user_id, data, math});
            if (result.status === 'REVERSED') {
                recommendations.push(result);
            }
        } else if (state.currentNode.type[0] && state.currentNode.type[0] === "components") {
            const component = plan.components[state.currentNode.type[1]];
            if (component && component.type === 'tier_table') { //this is a tier_table => try to reach a different result
                const {tiers} = component;
                let inputValue = state.inputNode.commissionPart;
                let [prevTier, nextTier] = tiers.reduce((result, tier, tierIndex) => {
                    const rangeStart = tier.range[0] === '' ? -Infinity : tier.range[0];
                    if (inputValue >= rangeStart) {
                        return [tiers[tierIndex - 1], tiers[tierIndex + 1]];
                    }
                    return result;
                }, []);
                let testTiers = [];
                if (nextTier) {
                    testTiers.push([
                        state.inputNode,
                        '>=',
                        nextTier.range[0]
                    ]);
                }
                if (prevTier) {
                    testTiers.push([
                        state.inputNode,
                        '<',
                        prevTier.range[1]
                    ]);
                }

                for (let testTier of testTiers) {
                    const result = optimizeCondition({
                        plan,
                        statesTree,
                        currentPath: state.currentPath,
                        user_id,
                        data,
                        math,
                        targetResult: true,
                        additionalData: {testTier}
                    });
                    if (result.status === 'REVERSED') {
                        let modifiedData = _.cloneDeep(data);
                        for (let [changed_metric_id, {
                            monthly_performance,
                            goal_percent
                        }] of Object.entries(result.changes)) {
                            modifiedData.monthly_performance[changed_metric_id][user_id] = monthly_performance;
                            modifiedData.goal_percent[changed_metric_id][user_id] = goal_percent;
                        }
                        const currentNode = _.get(plan, ['components'].concat(state.currentPath));
                        let resultCommission = calculateCommission({
                            plan,
                            currentNode,
                            user_id,
                            data: modifiedData,
                            math
                        });
                        if (resultCommission > state.commissionPart) {
                            recommendations.push({
                                ...result,
                                is_condition: true
                            });
                        }
                    }
                }
            }
        }
    }

    // find more possible recommendations on improving the metrics that affect the outcome the most
    let formula = reduceToFormula({plan, statesTree, currentPath: ['main', 'step'], user_id, data, math});
    const variables = {};
    collectVars(formula, variables);
    for (let metric_id of Object.keys(variables)) {
        let originalMetricValue = data.monthly_performance[metric_id][user_id] || 0;
        const leadType = data.lead_types[metric_id];
        let newMetricValue = _.round(leadType.measure_method === 1 ? originalMetricValue * 1.1 : originalMetricValue * 0.9, leadType.unit_type === 4 ? 2 : 0);
        const multiplier = newMetricValue / originalMetricValue;
        recommendations.push({
            status: "IMPROVED_METRIC",
            changes: {
                [metric_id]: {
                    originalMetricValue,
                    monthly_performance: newMetricValue,
                    goal_percent: data.goal_percent[metric_id][user_id] * multiplier
                }
            },
            is_condition: false
        });
    }

    if (recommendations.length) {
        for (let recommendation of recommendations) {
            let modifiedData = _.cloneDeep(data);
            for (let [changed_metric_id, {
                monthly_performance,
                goal_percent
            }] of Object.entries(recommendation.changes)) {
                modifiedData.monthly_performance[changed_metric_id][user_id] = monthly_performance;
                modifiedData.goal_percent[changed_metric_id][user_id] = goal_percent;
            }
            recommendation.newResult = calculateCommission({plan, currentNode, user_id, data: modifiedData, math});
            recommendation.improve_ratio = recommendation.newResult / originalResult.commissionPart;
        }
        recommendations = recommendations.filter(recommendation => recommendation.improve_ratio > 1).sort((a, b) => (Number(b.is_condition) - Number(a.is_condition)) || (b.improve_ratio - a.improve_ratio));
    }
    const recommendations_map = recommendations.reduce((result, recommendation, index) => {
        const key = Object.keys(recommendation.changes);
        if (!result[key]) { //filter out duplicate, less effective recommendations
            result[key] = {...recommendation, index};
        }
        return result;
    }, {});

    return {
        recommendations: Object.values(recommendations_map).sort((a, b) => a.index - b.index),
        originalResult
    };
};

const optimizeCondition = ({
                               plan,
                               statesTree,
                               currentPath,
                               user_id,
                               data,
                               math,
                               targetResult,
                               existingChanges = {},
                               additionalData
                           }) => {
    const stateNode = _.get(statesTree, currentPath);
    const currentPlanNode = stateNode.currentNode;
    const {type, name} = currentPlanNode;
    if (type === 'condition') {
        const result = optimizeCondition({
            plan,
            statesTree,
            currentPath: currentPath.concat('func'),
            user_id,
            data,
            math,
            existingChanges
        });
        if (result.status === 'REVERSED') {
            let modifiedData = _.cloneDeep(data);
            for (let [changed_metric_id, {monthly_performance, goal_percent}] of Object.entries(result.changes)) {
                modifiedData.monthly_performance[changed_metric_id][user_id] = monthly_performance;
                modifiedData.goal_percent[changed_metric_id][user_id] = goal_percent;
            }
            const currentNode = _.get(plan, ['components'].concat(currentPath));
            let resultCommission = calculateCommission({plan, currentNode, user_id, data: modifiedData, math});
            if (resultCommission > stateNode.commissionPart) {
                return {
                    ...result,
                    is_condition: true
                };
            }
        }
        return {status: "NOT_REVERSED"};
    } else if (type[0] === 'functions' && ['$and', '$or'].includes(name)) {
        const targetResult = !stateNode.commissionPart;
        let reversedResults = [];
        for (let i = 0; i < stateNode.args.length; i++) {
            let arg = stateNode.args[i];
            if (arg.commissionPart !== targetResult) {
                const result = optimizeCondition({
                    plan,
                    statesTree,
                    currentPath: currentPath.concat('args', i),
                    user_id,
                    data,
                    math,
                    targetResult,
                    existingChanges
                });
                if (result.status === 'REVERSED') {
                    existingChanges = {...existingChanges, ...result.changes};
                    if (!targetResult && name === '$and') {  //if targetResult=false then one successful FALSE is all it takes when using $and
                        return {
                            status: "REVERSED",
                            changes: existingChanges
                        };
                    } else if (targetResult && name === '$or') {  //if targetResult=true then one successful TRUE is all it takes when using $or
                        return {
                            status: "REVERSED",
                            changes: existingChanges
                        };
                    }
                    reversedResults.push(result);
                } else {
                    // result was not reversed
                }
            } else {
                reversedResults.push({status: "ALREADY_MATCHED"});
            }
        }

        if (reversedResults.length === stateNode.args.length) {
            return {
                status: "REVERSED",
                changes: existingChanges
            };
        } else {
            return {status: "NOT_REVERSIBLE"};
        }
    } else if ((type[0] === 'functions' && name === '$cond') || (type[0] === "components" && plan.components[type[1]] && plan.components[type[1]].type === 'tier_table')) {
        let args, testName, isTierTable;
        if (type[0] === "components" && plan.components[type[1]] && plan.components[type[1]].type === 'tier_table') {
            const {testTier} = additionalData;
            args = [
                testTier[0],
                undefined,
                {
                    currentNode: {
                        type: ['number']
                    },
                    commissionPart: testTier[2]
                }
            ]
            testName = testTier[1];
            isTierTable = true;
        } else {
            args = stateNode.args;
            testName = currentPlanNode.args[1].name;
        }
        if (['=', '!='].includes(testName)) {
            return {status: "NOT_REVERSIBLE"};
        }
        let reversibleArgs = [];
        for (let i = 0; i < args.length; i++) {
            if (i === 1) {
                continue; //this is the testName arg and so is not included here
            }
            let arg = args[i];
            let otherArg = i === 0 ? args[2] : args[0];
            let requestedResult;

            //decide what is the requestedResult based on the arg index, the testName and the targetResult - for example: first arg should be bigger but is not (since targetResult=true)...
            if (((i === 0 && testName.includes('>')) || (i === 2 && testName.includes('<'))) && targetResult === true) {
                requestedResult = otherArg.commissionPart;
                if (!testName.includes('=')) {
                    requestedResult += 0.01;
                }
            } else if (((i === 0 && testName.includes('<')) || (i === 2 && testName.includes('>'))) && targetResult === true) {
                requestedResult = otherArg.commissionPart;
                if (!testName.includes('=')) {
                    requestedResult -= 0.01;
                }
            } else if (((i === 0 && testName.includes('>')) || (i === 2 && testName.includes('<'))) && targetResult === false) {
                requestedResult = otherArg.commissionPart;
                if (!testName.includes('=')) {
                    requestedResult -= 0.01;
                }
            } else if (((i === 0 && testName.includes('<')) || (i === 2 && testName.includes('>'))) && targetResult === false) {
                requestedResult = otherArg.commissionPart;
                if (!testName.includes('=')) {
                    requestedResult += 0.01;
                }
            }

            const currentNode = isTierTable ? arg.currentNode : _.get(plan, ['components'].concat(currentPath.concat('args', i)));

            let formula = reduceToFormula({
                plan,
                statesTree,
                currentPath: isTierTable ? arg : currentPath.concat('args', i),
                user_id,
                data,
                math,
                existingChanges
            });

            const variables = {};
            collectVars(formula, variables);

            const currentResult = arg.commissionPart;
            // console.log("data.monthly_performance (kicdev) : ",data.monthly_performance);

            console.log("== 000 == kicdev ==");
            console.log({variables});
            varsIteration:
                for (let metric_id of Object.keys(variables)) {
                    console.log("== 111== kicdev ==");

                    // kicdev , new : TypeError: l.monthly_performance[Q] is undefined
                    var originalMetricValue = 0 ;
                    if ( data.monthly_performance.hasOwnProperty(metric_id) ) {
                        originalMetricValue = data.monthly_performance[metric_id][user_id] || 0;
                    }                    
                    // let originalMetricValue = data.monthly_performance[metric_id][user_id] || 0;
                    if( ! data.lead_types.hasOwnProperty(metric_id) ) {
                        console.log("(kicdev) : crash ")
                        console.log({metric_id});
                        continue; 
                    }
                    const leadType = data.lead_types[metric_id];
                    if ((requestedResult < currentResult && leadType.measure_method === 1) || (requestedResult > currentResult && leadType.measure_method === 2)) {
                        continue; //metric is ascending and need a lower result OR descending and need a higher result
                    }
                    /*
                        Attempt to reach requestedResult by adjusting this metric value
                        (reducing to formula / equation is not possible because target metric could be inside a function like round/min/max => so the best possible strategy would be to try several outcomes until target result is reached
                     */
                    // NOTICE: if things start to get slow => should probably run this in a worker
                    let multiplierDistance = 1;
                    let direction = leadType.measure_method === 1 ? 1 : -1;
                    let multiplier = (direction * multiplierDistance);
                    let targetResult = currentResult;

                    let modifiedData = _.cloneDeep(data);
                        
                    if (Object.keys(existingChanges).length) {
                        for (let [changed_metric_id, {
                            monthly_performance,
                            goal_percent
                        }] of Object.entries(existingChanges)) {
                            modifiedData.monthly_performance[changed_metric_id][user_id] = monthly_performance;
                            modifiedData.goal_percent[changed_metric_id][user_id] = goal_percent;
                        }
                        let result = calculateCommission({plan, currentNode, user_id, data: modifiedData, math});
                        if ((leadType.measure_method === 1 && result >= requestedResult) || (leadType.measure_method === 2 && result <= requestedResult)) {
                            //due to existingChanges requestedResult is already fulfilled
                            return {
                                status: "REVERSED",
                                targetResult: result,
                                changes: {}
                            };
                        }

                        if (existingChanges[metric_id]) {
                            continue; //changing an already changed metric is not allowed (becuase we would have to go back and re-test everything...)
                        }
                    }
                    let lastResults = [];
                    let gettingWorseCounter = 0;
                    let totalMoves = 0;
                    while ((lastResults.length < 2 || lastResults[0].targetResult !== lastResults[1].targetResult)) {
                        totalMoves++;
                        if (totalMoves >= 1600) {
                            console.error('too many iterations for var', {
                                metric_id,
                                totalMoves,
                                lastResults,
                                requestedResult,
                                direction,
                                leadType
                            });
                            break varsIteration;
                        }
                        
                     
                       
                        console.log("== 200 == kicdev ==");
                        console.log("modifiedData.monthly_performance = = ",{metric_id}) // Object { metric_id: "2737" }
                        console.log(modifiedData.monthly_performance[metric_id]) // Object { 4618: 0, 4623: 4, 4624: 0, 4625: 0, 4626: 0, 4627: 0, 4628: 0, 4629: 0, 4630: 0, 4631: 0, … }
                        console.log("---------------------------------------")
                        // TypeError: ie.monthly_performance[Q] is undefined
                        modifiedData.monthly_performance[metric_id][user_id] = _.floor(changeByPercent(modifiedData.monthly_performance[metric_id][user_id], multiplier), leadType.unit_type === 4 ? 2 : 0);
                        modifiedData.goal_percent[metric_id][user_id] = _.floor(changeByPercent(modifiedData.goal_percent[metric_id][user_id], multiplier), 2);
                        if (modifiedData.monthly_performance[metric_id][user_id] === 0) {
                            modifiedData.monthly_performance[metric_id][user_id] = (leadType.unit_type === 4) ? 0.01 : 1;
                            modifiedData.goal_percent[metric_id][user_id] = 0.01;
                        }

                        let result = calculateCommission({plan, currentNode, user_id, data: modifiedData, math});
                        console.log({
                            monthly_performance: modifiedData.monthly_performance[metric_id][user_id],
                            goal_percent: modifiedData.goal_percent[metric_id][user_id],
                            result,
                            requestedResult
                        });

                        targetResult = result;

                        if (targetResult === requestedResult) { //bingo
                            break;
                        }

                        if ((leadType.measure_method === 1 && direction === 1 && targetResult >= requestedResult) || (leadType.measure_method === 2 && direction === -1 && targetResult <= requestedResult)) {
                            lastResults.unshift({
                                targetResult,
                                metric_value: modifiedData.monthly_performance[metric_id][user_id]
                            });
                            lastResults = lastResults.slice(0, 2);
                            if (lastResults.length >= 2 && Math.abs(requestedResult - lastResults[0].targetResult) >= Math.abs(requestedResult - lastResults[1].targetResult) && Math.abs(multiplier) < 0.1) { //result is getting worse
                                gettingWorseCounter++;
                                console.log('result is getting worse', {
                                    gettingWorseCounter,
                                    requestedResult,
                                    lastResults
                                });
                                if (gettingWorseCounter >= 3) {
                                    targetResult = lastResults[1].targetResult;
                                    modifiedData.monthly_performance[metric_id][user_id] = lastResults[1].metric_value;
                                    modifiedData.goal_percent[metric_id][user_id] = data.goal_percent[metric_id][user_id] * (lastResults[1].metric_value / originalMetricValue);
                                    break;
                                }
                            }
                        }

                        const isChangeDirection = (direction === 1 && targetResult > requestedResult) || (direction === -1 && targetResult < requestedResult);
                        if (isChangeDirection) { //every time we need to change direction we also lower the pace at which we go to the target, each time getting closer and closer.
                            direction *= (-1);
                            multiplierDistance = multiplierDistance >= 1 ? multiplierDistance * 0.5 : multiplierDistance - (multiplierDistance * multiplierDistance);
                            multiplier = (direction * multiplierDistance);
                            console.log({direction, multiplierDistance, multiplier});
                        }
                    }

                    console.log({targetResult, requestedResult, totalMoves, metric_id, modifiedData});
                    variables[metric_id].isReversible = true;
                    variables[metric_id].metric_id = metric_id;
                    variables[metric_id].changeRatio = modifiedData.monthly_performance[metric_id][user_id] > originalMetricValue ? (modifiedData.monthly_performance[metric_id][user_id] / originalMetricValue) : (originalMetricValue / modifiedData.monthly_performance[metric_id][user_id]);
                    variables[metric_id].targetResult = targetResult;
                    variables[metric_id].changes = {
                        originalMetricValue,
                        monthly_performance: modifiedData.monthly_performance[metric_id][user_id],
                        goal_percent: modifiedData.goal_percent[metric_id][user_id]
                    };
                }

            const reversibleArg = Object.values(variables).filter(({isReversible}) => isReversible).sort((a, b) => a.changeRatio - b.changeRatio)[0];
            if (reversibleArg) {
                reversibleArgs.push(reversibleArg);
            }
        }

        if (reversibleArgs.length) {
            const reversedArg = reversibleArgs.sort((a, b) => a.changeRatio - b.changeRatio)[0];
            return {
                status: "REVERSED",
                targetResult,
                changes: {
                    [reversedArg.metric_id]: reversedArg.changes
                }
            };
        }

        return {status: "NOT_REVERSIBLE"};
    }
}
const collectVars = (formula, variables) => {
    if (formula.args) {
        for (let arg of formula.args) {
            collectVars(arg, variables);
        }
    } else if (formula.isVar) {
        variables[formula.name] = {isReversible: false};
    }
    return variables;
}
const reduceToFormula = ({plan, statesTree, currentPath, user_id, data, math, existingChanges}) => {
    const stateNode = Array.isArray(currentPath) ? _.get(statesTree, currentPath) : currentPath; //currentPath may be an object representing the current node it's a number/data/component
    const currentPlanNode = stateNode.currentNode;
    let {type, name} = currentPlanNode;

    if (type[0] === 'components' && type[1]) {
        type = type.slice(2);
    }

    if (type === 'math' || type === 'condition') {
        return reduceToFormula({
            plan,
            statesTree,
            currentPath: currentPath.concat('func'),
            user_id,
            data,
            math,
            existingChanges
        });
    } else if (type[0] === 'functions') {
        let result = {"op": name, args: []};
        for (let i = 0; i < stateNode.args.length; i++) {
            if (stateNode.args[i] === undefined) {
                continue;
            }
            result.args.push(
                reduceToFormula({
                    plan,
                    statesTree,
                    currentPath: currentPath.concat('args', i),
                    user_id,
                    data,
                    math,
                    existingChanges
                })
            );
        }
        return result;
    } else if (type[0] === 'number' || type[0] === 'string') {
        return {value: stateNode.commissionPart};
    } else if (type[0] === 'data') {
        if (type[1] === 'team_average_monthly_performance' || type[1] === 'employee_data' || type[1] === 'units') {
            return {value: stateNode.commissionPart};
        } else {
            return {type, name, isVar: true, originalValue: stateNode.commissionPart};
        }
    } else if (type[0] === 'components') {
        const component = plan.components[name];
        if (component.type === 'number') {
            return {value: stateNode.commissionPart};
        }

        return reduceToFormula({plan, statesTree, currentPath: [name, 'step'], user_id, data, math, existingChanges});
    }
};

/**
 * Calculates the commission - called recursively, walks through the plan and calculates the commission.
 * @param plan
 * @param currentNode
 * @param user_id
 * @param data
 * @param math - mathjs library
 * @param resultsCache - object to hold intermediate results by key of process component
 * @param isUnlinked
 */
const calculateCommission = ({plan, currentNode, user_id, data, math, resultsCache, isUnlinked = false}) => {
    console.log('calculating commission for ' + user_id);
    const {result} = runGeneratorFunction(calculateCommissionPart, {
        plan,
        currentNode,
        user_id,
        data,
        math,
        resultsCache,
        isUnlinked
    });
    return result.commissionPart;
}

/**
 * Calculates the commission - called recursively, walks through the plan and calculates the commission.
 *
 * NOTICE: this is a generator function. you will commonly see a pattern where we are delegating the generator to recursive calls via "yield*" and then collecting the results using "result = yield" (this is possible when using with runGeneratorFunction as it passes the last result as an argument). In the end the result is returned also by using "yield result".
 * @param plan
 * @param currentNode
 * @param currentPath
 * @param user_id
 * @param data
 * @param math - mathjs library
 * @param resultsCache - object to hold intermediate results by key of process component
 * @param isUnlinked
 */
const calculateCommissionPart = function* ({
                                               plan,
                                               currentNode,
                                               currentPath = [],
                                               user_id,
                                               data,
                                               math,
                                               resultsCache,
                                               isUnlinked = false
                                           }) {
    const {components} = plan;

    const prepareResult = (commissionPart) => {
        return {commissionPart, currentNode, currentPath};
    }

    const extractCommissionPart = (returnedObj) => {
        return returnedObj.commissionPart;
    }

    if (!currentNode) {
        currentNode = components.main;
        currentPath = currentPath.concat('main');
    }

    if (currentNode.type === 'process') {
        yield* calculateCommissionPart({
            plan,
            currentNode: currentNode.step,
            currentPath: currentPath.concat('step'),
            user_id,
            data,
            math,
            resultsCache,
            isUnlinked
        });
        const commissionPart = extractCommissionPart(yield);
        if (resultsCache) {
            resultsCache[currentNode.key] = commissionPart;

            if (currentNode === components.main) {
                for (let component of Object.values(components)) {
                    if (component.type === 'process' && !resultsCache[component.key] && component !== components.main) {
                        yield* calculateCommissionPart({
                            plan,
                            currentNode: component.step,
                            currentPath: [component.key, 'step'],
                            user_id,
                            data,
                            math,
                            resultsCache,
                            isUnlinked: true
                        });
                        const unLinkedCommissionPart = extractCommissionPart(yield);
                        if (resultsCache) {
                            resultsCache['#unlinked#' + component.key] = unLinkedCommissionPart;
                        }
                    }
                }
            }
        }
        yield prepareResult(commissionPart);
    } else if (currentNode.type === 'condition') {
        yield* calculateCommissionPart({
            plan,
            currentNode: currentNode.func,
            currentPath: currentPath.concat('func'),
            user_id,
            data,
            math,
            resultsCache,
            isUnlinked
        });
        const testResult = extractCommissionPart(yield);
        yield* calculateCommissionPart({
            plan,
            currentNode: testResult ? currentNode.step_true : currentNode.step_false,
            currentPath: currentPath.concat(testResult ? 'step_true' : 'step_false'),
            user_id,
            data,
            math,
            resultsCache,
            isUnlinked
        });
        const commissionPart = extractCommissionPart(yield);
        yield prepareResult(commissionPart);
    } else if (currentNode.type === 'math') {
        yield* calculateCommissionPart({
            plan,
            currentNode: currentNode.func,
            currentPath: currentPath.concat('func'),
            user_id,
            data,
            math,
            resultsCache,
            isUnlinked
        });
        const commissionPart = extractCommissionPart(yield);
        yield prepareResult(commissionPart);
    } else if (currentNode.type[0] === 'functions') {
        let args = currentNode.args.map((arg, index) => {
            if (arg) {
                arg.index = index;
            }
            return arg;
        }).filter(arg => arg); //filter nulls
        switch (currentNode.name) {
            case '$and':
            case '$or': {
                let finalResult = (currentNode.name === '$and');
                for (let arg of args) {
                    // if( currentNode.name==='$and' && !finalResult) { //if already false then no need to continue checking
                    //     break;
                    // }
                    // if( currentNode.name==='$or' && finalResult) { //if already true then no need to continue checking
                    //     break;
                    // }
                    // we're going to assess all conditions even if it's an $or and we already received FALSE => this is to get condition states for use in recommendations
                    yield* calculateCommissionPart({
                        plan,
                        currentNode: arg,
                        currentPath: currentPath.concat('args', arg.index),
                        user_id,
                        data,
                        math,
                        resultsCache,
                        isUnlinked
                    });
                    let result = extractCommissionPart(yield);
                    finalResult = ((currentNode.name === '$and' && !result) || (currentNode.name === '$or' && result)) ? result : finalResult;
                }
                yield prepareResult(finalResult);
                break;
            }
            case '$cond': {
                let values = [];
                for (let arg of [args[0], args[2]]) {
                    yield* calculateCommissionPart({
                        plan,
                        currentNode: arg,
                        currentPath: currentPath.concat('args', arg.index),
                        user_id,
                        data,
                        math,
                        resultsCache,
                        isUnlinked
                    });
                    values.push(extractCommissionPart(yield));
                }
                const tests = {
                    '=': () => Array.isArray(values[0]) ? values[0].includes(values[1]) : values[0] === values[1],
                    '!=': () => Array.isArray(values[0]) ? !values[0].includes(values[1]) : values[0] !== values[1],
                    '>=': () => values[0] >= values[1],
                    '<=': () => values[0] <= values[1],
                    '>': () => values[0] > values[1],
                    '<': () => values[0] < values[1]
                };
                yield prepareResult(tests[args[1].name]());
                break;
            }
            case '$add':
            case '$subtract':
            case '$multiply':
            case '$divide': {
                let mathOperation = math[currentNode.name.substr(1)];
                let funcArgs = [];
                for (let arg of args) {
                    yield* calculateCommissionPart({
                        plan,
                        currentNode: arg,
                        currentPath: currentPath.concat('args', arg.index),
                        user_id,
                        data,
                        math,
                        resultsCache,
                        isUnlinked
                    });
                    funcArgs.push(math.fraction(extractCommissionPart(yield)));
                }
                yield prepareResult((currentNode.name === '$divide' && !math.number(funcArgs[1])) ? 0 : math.number(mathOperation(...funcArgs))); //run math operation and convert result back into a JS Number
                break;
            }
            case '$round': {
                let mathOperation = math[currentNode.name.substr(1)];
                let funcArgs = [];
                for (let arg of args) {
                    yield* calculateCommissionPart({
                        plan,
                        currentNode: arg,
                        currentPath: currentPath.concat('args', arg.index),
                        user_id,
                        data,
                        math,
                        resultsCache,
                        isUnlinked
                    });
                    const argVal = extractCommissionPart(yield);
                    funcArgs.push(arg.index === 0 ? math.fraction(argVal) : argVal);
                }
                yield prepareResult(math.number(mathOperation(...funcArgs)));
                break;
            }
            case '$min':
            case '$max': {
                let mathOperation = math[currentNode.name.substr(1)];
                let funcArgs = [];
                for (let arg of args) {
                    yield* calculateCommissionPart({
                        plan,
                        currentNode: arg,
                        currentPath: currentPath.concat('args', arg.index),
                        user_id,
                        data,
                        math,
                        resultsCache,
                        isUnlinked
                    });
                    funcArgs.push(extractCommissionPart(yield));
                }
                yield prepareResult(mathOperation(...funcArgs));
                break;
            }
            default: { //shouldn't happen - throw error
                console.error('invalid commission plan - unexpected function name', {plan, currentNode, user_id});
                throw new Error('invalid commission plan - unexpected function name');
            }
        }
    } else if (currentNode.type[0] === 'components') {
        if (currentNode.type.length > 1) { //tier table
            const tierTable = components[currentNode.type[1]];
            const inputNode = {
                type: currentNode.type.slice(2),
                name: currentNode.name
            };
            yield* calculateCommissionPart({
                plan,
                currentNode: inputNode,
                currentPath: currentPath.concat('inputNode'),
                user_id,
                data,
                math,
                resultsCache,
                isUnlinked
            });
            const tableInput = extractCommissionPart(yield);
            const matchedTier = tierTable.tiers.filter(({range}) => (range[0] === '' || tableInput >= range[0]) && (range[1] === '' || tableInput < range[1]))[0];
            yield prepareResult(matchedTier ? Number(matchedTier.value) : 0);
        } else { //other components
            const component = components[currentNode.name];
            if (component.type === 'number') {
                yield prepareResult(Number(component.value));
            } else if (component.type === 'string') {
                yield prepareResult(component.value);
            } else if (component.type === 'process') {
                yield* calculateCommissionPart({
                    plan,
                    currentNode: component,
                    currentPath: [currentNode.name],
                    user_id,
                    data,
                    math,
                    resultsCache,
                    isUnlinked
                });
                const commissionPart = extractCommissionPart(yield);
                if (resultsCache) {
                    resultsCache[(isUnlinked ? '#unlinked#' : '') + component.key] = commissionPart;
                }
                yield prepareResult(commissionPart);
            } else {
                console.error('invalid commission plan - unknown component type', {plan, currentNode, user_id});
                throw new Error('invalid commission plan - unknown component type');
            }
        }
    } else if (currentNode.type[0] === 'data') {
        let val = _.get(data, [currentNode.type[1], currentNode.name, user_id]);
        if (currentNode.type[1] === 'units') {
            val = currentNode.name;
        }
        if (val === undefined) {
            console.info('in commission plan missing data for user', {currentNode, user_id});
            val = 0;
        }
        yield prepareResult(val);
    } else if (currentNode.type[0] === 'number') {
        yield prepareResult(Number(currentNode.name));
    } else if (currentNode.type[0] === 'string') {
        yield prepareResult(currentNode.name);
    } else { //shouldn't happen - throw error
        console.error('invalid commission plan', {plan, currentNode, user_id});
        throw new Error('invalid commission plan');
    }
};

module.exports = {
    calculateCommissionRecommendation,
    calculateCommission
};